import React, { useState } from 'react';

const CloseIcon = () => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    return (
        <svg width="60" height="60" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ cursor: 'pointer' }}
        >
            <g filter="url(#filter0_b_72_107)">
            <circle cx="34.2843" cy="33.2843" r="20" transform="rotate(-45 34.2843 33.2843)" fill="white" fill-opacity="0.8"/>
            </g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M35.8702 32.6945L40.3576 28.2071C40.5752 27.9895 40.5752 27.6087 40.3576 27.3912L39.5417 26.5753C39.3241 26.3577 38.9434 26.3577 38.7258 26.5753L34.2384 31.0627C34.0752 31.2259 33.8576 31.2259 33.6945 31.0627L29.2071 26.5753C28.9895 26.3577 28.6087 26.3577 28.3912 26.5753L27.5753 27.3912C27.3577 27.6087 27.3577 27.9895 27.5753 28.2071L32.0627 32.6945C32.2259 32.8576 32.2259 33.0752 32.0627 33.2384L27.5753 37.7258C27.3577 37.9434 27.3577 38.3241 27.5753 38.5417L28.3912 39.3576C28.6087 39.5752 28.9895 39.5752 29.2071 39.3576L33.6945 34.8702C33.8576 34.707 34.0752 34.707 34.2384 34.8702L38.7258 39.3576C38.9434 39.5752 39.3241 39.5752 39.5417 39.3576L40.3576 38.5417C40.5752 38.3241 40.5752 37.9434 40.3576 37.7258L35.8702 33.2384C35.707 33.0752 35.707 32.8576 35.8702 32.6945Z" 
            fill="#262626"
            fillOpacity={isHovered ? 0.9 : 0.75}
            style={{ transition: 'fill-opacity 0.3s ease-in-out' }}
            />
            <defs>
            <filter id="filter0_b_72_107" x="-3.51582" y="-4.5157" width="75.6" height="75.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="8.9"/>
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_72_107"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_72_107" result="shape"/>
            </filter>
            </defs>
        </svg>
    );
};

export default CloseIcon;