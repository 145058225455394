import React from 'react';
import './PrincipleCard.scss';
import ResponsiveTextBox from '../ResponsiveTextBox/ResponsiveTextBox';

function PrincipleCard({id, principle}) {
    console.log(id, principle);
    return (
        <div className='container'>
            <div className="parentCard">
                <ResponsiveTextBox text={principle} />
            </div>
            <div className='lowerPrincipleCardBanner'></div>
        </div>
    );
}

export default PrincipleCard;
