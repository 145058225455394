import React, { useState, useRef, useEffect } from 'react';
import PrincipleCard from '../Components/PrincipleCard/PrincipleCard';
import './HomePage.scss';
import { Typography } from '@mui/material';
import SettingsIcon from '../Icons/Settings';
import SlideUpContentPanel from '../Components/ContentPanel/SlideUpContentPanel';
import CloseIcon from '../Icons/Close';

const data = [
    {
        id: 'jfdklsjflkdsjflkds',
        principle: `Truth—or, more precisely, an accurate understanding of reality—is the essential foundation for any good outcome.`,
    },
    {
        id: 'oiweuroiweuroiweuroi',
        principle: `Be radically open-minded and radically transparent.`,
    },
    {
        id: 'qwoeiruqwoeiruqwoeiru',
        principle: `Evolution is the single greatest force in the universe; it is the only thing that is permanent and it drives everything.`,
    },
    {
        id: 'qwoeiruqwoeiruqwoeiru',
        principle: `To be "good" something must operate consistently with the laws of reality and contribute to the evolution of the whole; that is what is most rewarded.`,
    },
    {
        id: 'jfdklsjflkdsjflkds',
        principle: `Think of yourself as a machine operating within a machine and know that you have the ability to alter your machines to produce better outcomes.`,
    },
    {
        id: 'oiweuroiweuroiweuroi',
        principle: `Asking others who are strong in areas where you are weak to help you is a great skill that you should develop no matter what, as it will help you develop guardrails that will prevent you from doing what you shouldn't be doing.`,
    },
    
];

const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return "Good morning";
    if (hour < 18) return "Good afternoon";
    return "Good evening";
};


function HomePage() {
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [isScaledIcon, setIsScaledIcon] = useState(false);
    const settingsButtonRef = useRef(null);
    const closeButtonRef = useRef(null);

    useEffect(() => {
        if (isSettingsOpen) {
            setIsScaledIcon(true);
        } else {
            setIsScaledIcon(false);
        }
    }, [isSettingsOpen]);

    const toggleSettings = () => {
        setIsSettingsOpen((prevState) => !prevState);
    };


    return (
        <div className='homePageContainer'>

        <div className='rightIcon'>
                <button
                    onClick={toggleSettings}
                    style={{ background: 'transparent', border: 'none', padding: 0 }}
                    ref={settingsButtonRef}
                    className={`settings-button ${isScaledIcon ? 'scaled' : ''}`}
                >
                    {isSettingsOpen ? (
                        <button
                            ref={closeButtonRef}
                            style={{ background: 'transparent', border: 'none', padding: 0 }}
                            className={`close-button ${isScaledIcon ? 'scaled' : ''}`}
                        >
                            <CloseIcon />
                        </button>
                    ) : (
                        <SettingsIcon />
                    )}
                </button>
            </div>

            <div className={`infoActionBar ${isSettingsOpen ? 'hidden' : ''}`}>
                <Typography variant='h4' className='title' style={{ color: 'white' }}>👋 {getGreeting()}, Ben</Typography>
            </div>

            <SlideUpContentPanel isOpen={isSettingsOpen} onClose={toggleSettings} settingsButtonRef={settingsButtonRef}/>

            <div className={`gridContainer ${isSettingsOpen ? 'hidden' : ''}`}>
                {data.map(principle => (
                    <PrincipleCard key={principle.id} principle={principle.principle} />
                ))}
            </div>
        </div>
    );
}

export default HomePage;
