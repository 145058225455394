import React, { useRef, useEffect } from 'react';
import './SlideUpContentPanel.scss';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';

const SlideUpContentPanel = ({ isOpen, onClose, settingsButtonRef }) => {

    const contentRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (contentRef.current && 
                !contentRef.current.contains(event.target) && 
                settingsButtonRef.current !== event.target &&
                !settingsButtonRef.current.contains(event.target)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose, settingsButtonRef]);

    return (
        <div className={`contentContainer ${isOpen ? 'open' : ''}`} ref={contentRef}>
            <div className='topBanner'>
                <Typography variant='h5' className='title' style={{ color: '#272727' }}>Settings</Typography>
            </div>
            <div className='childContentContainer'>
                <div className='logOutButton'>
                <Button variant="text" style={{ color: '#000000', textTransform: 'none' }}>Log out</Button>
                </div>
            </div>
        </div>
    );
};

export default SlideUpContentPanel;