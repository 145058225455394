import React, { useRef, useEffect, useState, useCallback } from 'react';

const ResponsiveTextBox = ({ text }) => {
  const containerRef = useRef(null);
  const textRef = useRef(null);
  const [fontSize, setFontSize] = useState(16);

  const resizeText = useCallback(() => {
    const container = containerRef.current;
    const textElement = textRef.current;
    if (!container || !textElement) return;

    let low = 1;
    let high = 100;
    let mid;

    while (low <= high) {
      mid = Math.floor((low + high) / 2);
      textElement.style.fontSize = `${mid}px`;

      if (textElement.scrollHeight <= container.clientHeight && 
          textElement.scrollWidth <= container.clientWidth) {
        low = mid + 1;
      } else {
        high = mid - 1;
      }
    }

    console.log('Final size:', high);
    setFontSize(high);
    
    // Force immediate update of the text element
    textElement.style.fontSize = `${high}px`;
  }, []);

  useEffect(() => {
    resizeText();

    const debouncedResize = debounce(resizeText, 100);
    const resizeObserver = new ResizeObserver(debouncedResize);
    const currentContainer = containerRef.current;
    
    if (currentContainer) {
      resizeObserver.observe(currentContainer);
    }

    window.addEventListener('resize', debouncedResize);

    return () => {
      if (currentContainer) {
        resizeObserver.unobserve(currentContainer);
      }
      window.removeEventListener('resize', debouncedResize);
    };
  }, [resizeText, text]);

  return (
    <div
      ref={containerRef}
      style={{
        width: '90%',
        height: '90%',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
      }}
    >
      <div
        ref={textRef}
        style={{
          fontSize: `${fontSize}px`,
          lineHeight: 1.2,
          textAlign: 'left',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#282828',
        }}
      >
        {text}
      </div>
    </div>
  );
};

// Debounce function
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export default ResponsiveTextBox;