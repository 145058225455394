import React, { useState } from 'react';

const SettingsIcon = () => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    return (
        <svg width="60" height="60" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg</svg>" 
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ cursor: 'pointer' }}
        >
            <path fill-rule="evenodd" clip-rule="evenodd" d="M48.2908 37.2361L45.7268 35.0623C45.894 34.282 45.9498 33.4459 45.9498 32.6656C45.9498 31.8852 45.894 31.0492 45.7268 30.2689L48.2908 28.0951C49.1268 27.3705 49.4055 26.1443 48.8481 25.141L47.7334 23.1902C47.2875 22.4656 46.5629 22.0754 45.7268 22.0754C45.4481 22.0754 45.1695 22.1311 44.9465 22.2426L41.7137 23.4131C40.4317 22.2984 39.0383 21.518 37.5891 21.0164L37.0317 17.7279C36.8088 16.6131 35.8613 16 34.7465 16H32.517C31.4022 16 30.399 16.6131 30.2317 17.7279L29.6744 20.9607C28.1137 21.4623 26.7203 22.2984 25.494 23.3574L22.2055 22.1869C22.105 22.1668 22.0118 22.1394 21.9206 22.1127C21.7588 22.0652 21.6034 22.0197 21.4252 22.0197C20.5891 22.0197 19.8088 22.4656 19.4186 23.1344L18.3039 25.0852C17.7465 26.0885 17.9695 27.3148 18.8613 28.0393L21.4252 30.2131C21.258 30.9934 21.2022 31.8295 21.2022 32.6098C21.2022 33.4459 21.258 34.2262 21.4252 35.0066L18.8613 37.1803C18.0252 37.9049 17.7465 39.1311 18.3039 40.1344L19.4186 42.0852C19.8645 42.8098 20.5891 43.2 21.4252 43.2C21.7039 43.2 21.9826 43.1443 22.2055 43.0328L25.4383 41.8623C26.7203 42.977 28.1137 43.7574 29.5629 44.259L30.1203 47.6033C30.3432 48.718 31.235 49.4984 32.4055 49.4984H34.635C35.7498 49.4984 36.7531 48.6623 36.9203 47.5475L37.4776 44.2033C39.094 43.6459 40.5432 42.8098 41.8252 41.6393L44.835 42.8098C44.9279 42.8284 45.0208 42.8532 45.1137 42.878L45.1137 42.878C45.2995 42.9275 45.4853 42.977 45.6711 42.977C46.5072 42.977 47.2875 42.5311 47.6776 41.8623L48.7367 40.023C49.4055 39.1869 49.1268 37.9607 48.2908 37.2361ZM33.6317 27.9279C30.9006 27.9279 28.7268 30.1016 28.7268 32.8328C28.7268 35.5639 30.9006 37.7377 33.6317 37.7377C36.3629 37.7377 38.5367 35.5639 38.5367 32.8328C38.5367 30.1016 36.3629 27.9279 33.6317 27.9279ZM25.9399 32.8328C25.9399 37.0689 29.3399 40.5246 33.6317 40.5246C37.8678 40.5246 41.3236 37.0689 41.3236 32.8328C41.3236 28.5967 37.8678 25.141 33.6317 25.141C29.3957 25.141 25.9399 28.5967 25.9399 32.8328Z" 
            fill="white"
            fillOpacity={isHovered ? 0.9 : 0.75}
            style={{ transition: 'fill-opacity 0.3s ease-in-out' }}/>
        </svg>
    );
};

export default SettingsIcon;

