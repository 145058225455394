import React from 'react';
import greenImage from './Themes/earth.png';
import PrinciplesPage from './Pages/HomePage';
import './App.scss';

const App = () => {
  return (
    <div >
      <div
        className="absolute inset-0"
        style={{
          backgroundImage: `url("${greenImage}")`,
          filter: 'blur(8px)',
          transform: 'scale(1.2)',
          width: '100%',
          height: '100%',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      />
   
        <div className="main">

          <PrinciplesPage />
        </div>

    </div>
  );
};

export default App;
